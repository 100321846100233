import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { SectionWrapper, H2 } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    padding: 106px 20px 82px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;
export const Title = styled(H2)`
    padding-bottom: 64px;
    text-align: center;

    ${mediaQueries.md} {
        padding-bottom: 48px;
    }
    ${mediaQueries.xs} {
        text-align: left;
    }
`;

export const AdvantageWrapper = styled.div`
    padding-bottom: 60px;
    display: grid;
    gap: 50px 50px;
    grid-template-columns: 1fr 1fr 1fr;

    ${mediaQueries.md} {
        padding: 0 0 48px;
        gap: 0 40px;
        grid-template-columns: 1fr 1fr;
    }

    ${mediaQueries.xs} {
        grid-template-columns: 1fr;
    }
`;

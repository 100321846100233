import { UniversalHero } from 'components/UniversalHero/UniversalHero';
import Layout from 'layout/index';
import * as React from 'react';
import { LiveCofortably } from 'sections/LiveComfortably/LiveComfortably';
import { NewInvestment } from 'sections/NewInvestment/NewInvestment';
import { SafeCredit } from 'sections/SafeCredit/SafeCredit';
import { getDisabledPages } from 'utils/utils';

interface AboutInvestmentPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: AboutInvestmentPageProps) => {
    const aboutInvestmentData =
        pageContext?.data?.wpInvestment?.investment?.aboutInvestmentPage;

    const pageTitle = `${pageContext?.title} - ${aboutInvestmentData?.title}`;

    const showSafeCreditSection =
        aboutInvestmentData?.safeCreditButton &&
        aboutInvestmentData?.safeCreditDescription &&
        aboutInvestmentData?.safeCreditImage1 &&
        aboutInvestmentData?.safeCreditImage2 &&
        aboutInvestmentData?.safeCreditTitle;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <UniversalHero
                    background={aboutInvestmentData?.titleBackground?.sourceUrl}
                    text={aboutInvestmentData?.description}
                    title={aboutInvestmentData?.title}
                />
                {showSafeCreditSection && (
                    <SafeCredit
                        contactButton={aboutInvestmentData?.safeCreditButton}
                        description={aboutInvestmentData?.safeCreditDescription}
                        imageLeft={aboutInvestmentData?.safeCreditImage1}
                        imageRight={aboutInvestmentData?.safeCreditImage2}
                        title={aboutInvestmentData?.safeCreditTitle}
                    />
                )}
                <NewInvestment
                    descriptionLeft={aboutInvestmentData?.descriptionLeft}
                    descriptionRight={aboutInvestmentData?.descriptionRight}
                    imageLeft={aboutInvestmentData?.imageLeft}
                    imageRight={aboutInvestmentData?.imageRight}
                    keyFeaturesRight={aboutInvestmentData?.keyFeaturesRight}
                    titleRight={aboutInvestmentData?.titleRight}
                />
                <LiveCofortably
                    advantages={aboutInvestmentData?.advantages}
                    advantagesTitle={aboutInvestmentData?.advantagesTitle}
                    investmentSlug={pageContext?.slug}
                />
            </>
        </Layout>
    );
};

import { SingleAdvantage } from 'components/SingleAdvantage/SingleAdvantage';
import React from 'react';
import { PrimaryButton } from 'styles/Typography.styled';
import { AdvantagesType } from 'types/CommonTypes';
import {
    AdvantageWrapper,
    StyledSectionWrapper,
    Title,
} from './LiveComfortably.styled';

export interface LiveCofortablyProps {
    advantages: AdvantagesType[];
    advantagesTitle: string;
    investmentSlug: string;
}

export const LiveCofortably = ({
    advantages,
    advantagesTitle,
    investmentSlug,
}: LiveCofortablyProps) => {
    return (
        <StyledSectionWrapper>
            <Title dangerouslySetInnerHTML={{ __html: advantagesTitle }} />
            <AdvantageWrapper>
                {advantages?.map((item) => (
                    <SingleAdvantage
                        title={item.title}
                        text={item.description}
                        icon={item.icon}
                    />
                ))}
            </AdvantageWrapper>
            <PrimaryButton to={'/' + investmentSlug + '/mieszkania'}>
                Znajdź swoje mieszkanie
            </PrimaryButton>
        </StyledSectionWrapper>
    );
};

import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import {
    H2,
    H3,
    H5,
    HeroText,
    PrimaryButton,
    SecondaryButton,
} from 'styles/Typography.styled';

export const Container = styled.div(
    ({ theme: { colors } }) => css`
        background: ${colors.primaryBlue50};
        display: grid;
        padding: 80px 130px;
        position: relative;
        width: 100%;

        ${mediaQueries.lg} {
            padding: 80px 100px;
        }

        ${mediaQueries.md} {
            margin-bottom: 40px;
            padding: 80px 36px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ${mediaQueries.sm} {
            background: ${colors.white};
            margin-bottom: 0;
            padding: 40px;
        }

        ${mediaQueries.xs} {
            padding: 16px 16px 56px 16px;
        }
    `,
);

export const InfoTitle = styled(H5)(
    ({ theme: { letterSpacing } }) =>
        css`
            letter-spacing: ${letterSpacing.s};
            line-height: 28px;
        `,
);

export const Building = styled(H2)(
    ({ theme: { fontSizes, letterSpacing } }) =>
        css`
            font-size: ${fontSizes.f12};
            letter-spacing: ${letterSpacing.m};
            line-height: 16px;
            text-transform: uppercase;
        `,
);

export const ColorText = styled(Building)<{ isFree: string }>(
    ({ theme: { colors }, isFree }) =>
        css`
            color: ${isFree === 'Wolne'
                ? colors.green
                : isFree === 'Sprzedane'
                ? colors.error
                : colors.yellow};
        `,
);

export const InfoValue = styled(HeroText)`
    line-height: 28px;
`;

export const InfoWrapper = styled.div(
    ({ theme: { colors } }) => css`
        border-bottom: 2px solid ${colors.primaryBlue200};
        display: flex;
        justify-content: space-between;
        padding: 16px 0;

        &:last-of-type {
            border: unset;
            padding: 12px 0;
        }

        ${mediaQueries.sm} {
            border-bottom: unset;
        }
    `,
);

export const Wrapper = styled.div`
    padding-bottom: 36px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)(
    () => css`
        cursor: pointer;
        width: 100%;
    `,
);

export const StyledSecondaryButton = styled(SecondaryButton)`
    cursor: pointer;
    display: flex;
    gap: 16px;
    padding: 16px;
    width: 100%;
`;

export const ButtonsWrapper = styled.div`
    align-self: flex-end;
    display: grid;
    grid-gap: 16px;

    ${mediaQueries.xs} {
        padding: 0 32px;
    }
`;

export const InfoValueWrapper = styled.div<{ margin?: boolean }>(
    ({ margin }) => css`
        align-items: center;
        display: flex;
        gap: 8px;
        ${margin &&
        css`
            margin: 8px 0 20px;

            ${mediaQueries.sm} {
                margin: 8px 0 40px;
            }
        `}
    `,
);

export const Price = styled(H3)(
    ({ theme: { fontSizes, colors } }) =>
        css`
            color: ${colors.primaryBlue400};
            font-size: ${fontSizes.f40};

            ${mediaQueries.md} {
                font-size: ${fontSizes.f24};
            }
        `,
);

export const HeartWrapper = styled.div`
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);

    ${mediaQueries.sm} {
        padding: 0 32px;
        right: 0;
    }
`;

export const FlatHeader = styled.div`
    display: flex;
    position: relative;
`;

export const IconWrapper = styled.div`
    width: 32px;
    height: 32px;
`;

export const FeaturedBox = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.primaryBlue100};
        display: flex;
        gap: 16px;
        padding: 16px;
        margin: 32px 0 24px;

        ${mediaQueries.sm} {
            margin-bottom: 40px;
        }
    `,
);

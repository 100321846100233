import { Markdown } from 'components/Markdown/Markdown';
import { NumberWithText } from 'components/NumberWithText/NumberWithText';
import React from 'react';
import { TitleDesriptionType, ImageType } from 'types/CommonTypes';
import {
    Image,
    ImageMobile,
    InfoBox,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextLeft,
    TextRight,
    Title,
} from './NewInvestment.styled';

export interface NewInvestmentProps {
    descriptionLeft: string;
    descriptionRight: string;
    imageLeft: ImageType;
    imageRight: ImageType;
    keyFeaturesRight: TitleDesriptionType[];
    titleRight: string;
}

export const NewInvestment = ({
    descriptionLeft,
    descriptionRight,
    imageLeft,
    imageRight,
    keyFeaturesRight,
    titleRight,
}: NewInvestmentProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <Image alt={imageLeft?.altText} src={imageLeft?.sourceUrl} />
                <ImageMobile
                    alt={imageRight?.altText}
                    src={imageRight?.sourceUrl}
                />
                <TextLeft>{descriptionLeft}</TextLeft>
            </LeftWrapper>

            <RightWrapper>
                <Markdown markdown={titleRight} />
                <TextRight>{descriptionRight}</TextRight>
                <ImageMobile
                    alt={imageLeft?.altText}
                    src={imageLeft?.sourceUrl}
                />
                <InfoBox>
                    {keyFeaturesRight?.map((item) => (
                        <NumberWithText
                            number={item?.title}
                            text={item?.description}
                        />
                    ))}
                </InfoBox>
                <Image alt={imageRight?.altText} src={imageRight?.sourceUrl} />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};

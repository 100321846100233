import React from 'react';
import { ImageType } from 'types/CommonTypes';
import {
    ButtonWrapper,
    Image,
    ImageMobile,
    LeftWrapper,
    RightWrapper,
    StyledSectionWrapper,
    TextDescription,
} from './SafeCredit.styled';
import { StyledSecondaryButton } from 'components/FlatInfo/FlatInfo.styled';
import { H2 } from 'styles/Typography.styled';

export interface SafeCreditProps {
    contactButton: {
        target: string;
        title: string;
        url: string;
    };
    description: string;
    imageLeft: ImageType;
    imageRight: ImageType;
    title: string;
}

export const SafeCredit = ({
    contactButton,
    description,
    imageLeft,
    imageRight,
    title,
}: SafeCreditProps) => {
    return (
        <StyledSectionWrapper>
            <LeftWrapper>
                <H2>{title}</H2>
                <TextDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <ButtonWrapper>
                    <StyledSecondaryButton
                        target={contactButton?.target}
                        to={contactButton?.url}
                    >
                        {contactButton?.title}
                    </StyledSecondaryButton>
                </ButtonWrapper>
            </LeftWrapper>
            <RightWrapper>
                <Image alt={imageLeft?.altText} src={imageLeft?.sourceUrl} />
                <Image alt={imageRight?.altText} src={imageRight?.sourceUrl} />
                <ImageMobile
                    alt={imageLeft?.altText}
                    src={imageLeft?.sourceUrl}
                />
                <ImageMobile
                    alt={imageRight?.altText}
                    src={imageRight?.sourceUrl}
                />
            </RightWrapper>
        </StyledSectionWrapper>
    );
};
